$(function() {
  var tableBlock = $('.b-table');

  tableBlock.each(function() {
    var table = $(this).find('table');

    var headings = [];
    table.find('thead tr').first().find('td').each(function() {
      headings.push($(this).html());
    });

    if (headings.length) {
      table.find('tbody tr').each(function() {
        $(this).find('td').each(function(i) {
          $(this).before('<td class="head">' + headings[i] + '</td>');
        });
      });
    }
  });
});
