$(function() {
  var header = $('.c-header');
  var headerHeight = header.outerHeight(true);
  var mobileNav = header.find('.c-header-links-mobile');
  var desktopNav = header.find('.c-header-links-desktop');
  var buttonClass = 'show-children';
  var openClass = 'open';
  var fakeHeaderClass = 'fake-header';
  header.before('<div class="' + fakeHeaderClass + '"></div>');
  var fakeHeader = $('.' + fakeHeaderClass);

  mobileNav.find('a').each(function() {
    if (!$(this).siblings('ul').length) {
      return;
    }

    $(this).after('<button class="' + buttonClass + '"></button>');
  });

  mobileNav.find('.' + buttonClass).on('click', function() {
    var children = $(this).siblings('ul');

    if (!children.length) {
      return;
    }

    $(this).toggleClass(openClass);
    children.toggleClass(openClass);
  });

  // TODO:
  // - Change headerHeight on resize and showChildren click

  fakeHeader.outerHeight(headerHeight);

  // Headroom
  var headroomOptions = {
    offset: headerHeight,
    tolerance: 10,
    onTop: function() {
      // fakeHeader.outerHeight(0);
    },
    onNotTop: function() {
      // fakeHeader.outerHeight(headerHeight);
      // header.removeClass(openClass);
    },
  }

  $('.show-links').on('click', function() {
    header.toggleClass(openClass);
  });

  header.headroom(headroomOptions);

  // Create focus area
  var keepFocusArray = [
    header.find('.c-header-content'),
  ];
  var keepFocus = $([]);
  for (var i = 0; i < keepFocusArray.length; i++) {
    keepFocus = keepFocus.add(keepFocusArray[i]);
  }

  keepFocus.on('click', function(e) {
    e.stopPropagation();
  });

  // Hide on click outside of focus area
  $(window).on('click', function() {
    if (header.hasClass(openClass)) {
      header.removeClass(openClass);
    }
  });

  setInterval(function() {
    if (header.hasClass(openClass)) {
      $('body').addClass('no-scroll');
    } else {
      $('body').removeClass('no-scroll');
    }
  }, 1000);
});
