$(function() {
  function fakeHeight() {
    var elementName = 'fake-height';
    var elementSelector = '[data-' + elementName + ']';
    var elementClassName = 'fake-height-class';
    var elementClassSelector = '[data-' + elementClassName + ']';
    var wrapperName = 'fake-height-wrapper';
    var wrapperSelector = '.' + wrapperName;
    var sourceName = 'fake-height-source';
    var sourceSelector = '[data-' + sourceName + ']';
    var elements = $(elementSelector);

    elements.each(function() {
      var element = $(this);

      if (!element.parent(wrapperSelector).length) {
        var wrapperClass = wrapperName;
        var elementClass = element.attr('data-' + elementClassName);

        if (elementClass !== undefined && elementClass !== '') {
          wrapperClass += ' ' + elementClass;
        }

        element.wrap('<div class="' + wrapperClass + '"></div>');
      }

      var wrapper = element.parent(wrapperSelector);

      if (!wrapper.length) {
        return true;
      }

      var source = element.find(sourceSelector);

      if (!source.length) {
        source = element;
      }

      var sourceHeight = 0;
      source.each(function() {
        sourceHeight += $(this).outerHeight();
      });

      wrapper.outerHeight(sourceHeight);
    });
  }

  (function loopFakeHeight() {
    fakeHeight();
    setTimeout(loopFakeHeight, 1000);
  })();
});
