$(function() {
  // Show edit button via shortcut
  Mousetrap.bindGlobal('command+shift+z', function(e) {
    $('.edit-button').toggleClass('hide');
    return false;
  });

  // // Disable behavior of fake links
  $('a[href="#"]').on('click', function(e) {
    e.preventDefault();
  });

  // // Smooth scroll
  // $('a').smoothScroll();

  // // Iframe resize
  // $('.c-map').iFrameResize({
  //   scrolling: true,
  // });

  // Masonry & imagesLoaded
  var $masonry = $('.masonry').masonry({
    itemSelector: '.masonry-item',
  });
  $masonry.imagesLoaded().progress(function() {
    $masonry.masonry('layout');
  });

  $('.tabs').on('change.zf.tabs', function() {
    $masonry.masonry('layout');
  });

  setInterval(function() {
    $masonry.masonry('layout');
  }, 3000);

  // // Description
  // $('.c-masonry img[alt]').each(function() {
  //   var image = $(this);
  //   var alt = image.attr('alt');
  //   image.after('<div class="img-description">' + alt + '</div>');
  // });

  // Scroll animations
  // ScrollMagic

  var ZellenkurAnimate = function(options) {
    options = typeof options !== 'undefined' ? options : {};
    this.options = options;
    this.controller = this.initController(this.options);

    $('html').addClass('scrollmagic');
  };
  ZellenkurAnimate.prototype = {
    initController: function(options) {
      options = typeof options !== 'undefined' ? options : {};
      var controller = new $.ScrollMagic.Controller(options);

      return controller;
    },
    animate: function(selector, animation, delay, delayDuration, animationDuration) {
      delay = typeof delay !== 'undefined' ? delay : 0;
      delayDuration = typeof delayDuration !== 'undefined' ? delayDuration : 0;
      animationDuration = typeof animationDuration !== 'undefined' ? animationDuration : 1000;

      if (!(selector instanceof jQuery)) {
        selector = $(selector);
      }

      var animationClasses = 'animated ' + animation;
      var visibleClass = 'animated-visible';
      var that = this;

      selector.each(function(i) {
        var element = $(this);
        var elementDelay = delay + (i * delayDuration);

        var scene = new $.ScrollMagic.Scene({
          triggerElement: element,
          reverse: false,
        })
        .on('start', function(event) {
          _.delay(function() {
            element.addClass(animationClasses);
            element.addClass(visibleClass);

            _.delay(function() {
              element.removeClass(animationClasses);
            }, animationDuration);
          }, elementDelay);
        })
        .addTo(that.controller);
      });

      return this;
    },
  };

  if (!$('html').hasClass('live-preview')) {
    var zkrAnimate = new ZellenkurAnimate({
      refreshInterval: 500,
      globalSceneOptions: {
        triggerHook: .95,
      },
    });

    zkrAnimate
    .animate('.c-header-advice-content', 'fadeIn', 500)
    .animate('.c-header-logo', 'fadeIn', 500)
    .animate('.c-header-links-desktop > li > a', 'fadeIn', 1000, 250)
    .animate('.b-themes-teaser .b-themes-teaser-entry', 'fadeIn', 0, 250)
    .animate('.b-text', 'fadeIn', 500)
    .animate('.b-gallery', 'fadeIn', 500)
    .animate('.b-call-to-action', 'fadeIn', 500)
    .animate('.b-navigation', 'fadeIn', 500)
    .animate('.b-table', 'fadeIn', 500)
    .animate('.b-button', 'flipInX', 1000)
    .animate('.b-themes-teaser .c-icon', 'zoomIn', 0, 250)
    .animate('.b-contents-teaser .c-icon', 'zoomIn', 0, 250)
    .animate('.b-printing-methods-teaser .c-icon', 'zoomIn', 0, 250)
    .animate('.b-icon .c-icon', 'zoomIn', 0, 250)
    .animate('.b-list .b-list-item', 'fadeIn', 500, 375)
    .animate('.c-sticker', 'zoomIn', 1000, 250)
    // .animate('.c-zellenkur', 'rotateIn', 1000)
    .animate('.c-footer', 'fadeIn', 500);
  }

  // // ScrollReveal
  // var scrollreveal = ScrollReveal({
  //   duration: 500,
  //   viewFactor: 0.33,
  // });

  // if (scrollreveal.isSupported()) {
  //   $('html').addClass('scrollreveal');
  // }

  // // var selector = '#header .dropdown-menu-wrapper > .dropdown.menu > li > a';
  // // scrollreveal.reveal(selector, {}, 100);

  // // $('.events .event').wrap('<div class="sc-wrapper"></div>');
  // // var selector = '.events .sc-wrapper';
  // // scrollreveal.reveal(selector, {}, 100);

  // // $('.sidebar > *').wrap('<div class="sc-wrapper"></div>');
  // // var selector = '.sidebar .sc-wrapper';
  // // scrollreveal.reveal(selector, {}, 100);

  // // $('#footer > .content > *').wrap('<div class="sc-wrapper"></div>');
  // // var selector = '#footer .sc-wrapper';
  // // scrollreveal.reveal(selector, {
  // //   duration: 1000,
  // // }, 100);
});

$(document).foundation();

runOnLoad(Dropdown.initialise);
