$(function() {
  var gallery = $('.b-gallery');
  var slider = gallery.find('.c-slider');
  var prevArrow = 'slick-prev';
  var nextArrow = 'slick-next';

  slider.slick({
    autoplay: true,
    autoplaySpeed: 4000,
    pauseOnHover: true,
    arrows: true,
    focusOnSelect: true,
    prevArrow: '<button type="button" class="' + prevArrow + '"></button>',
    nextArrow: '<button type="button" class="' + nextArrow + '"></button>',
  })
  .on('beforeChange', function(e, slick, currentSlide) {
    var current = $(this).find('[data-slick-index="' + currentSlide + '"]');
    var iframe = current.find('iframe[src]');

    if (!iframe.length) {
      return false;
    }

    var command = {};
    var source = iframe.attr('src');

    if (source.indexOf('vimeo.com') > -1) {
      command = {
        'method': 'pause',
      };
    } else if (source.indexOf('youtube.com') > -1) {
      command = {
        'event': 'command',
        'func': 'pauseVideo',
      };
    } else {
      return false;
    }

    if (typeof iframe.get(0).contentWindow === 'undefined' ||
      iframe.get(0).contentWindow === null) {
      return false;
    }

    iframe.get(0).contentWindow.postMessage(JSON.stringify(command), '*');
  });

  var navArrowsSelector = '.' + prevArrow + ', .' + nextArrow;

  slider.find(navArrowsSelector).on('click', function(e) {
    return false;
  });

  // Iframe fix
  slider.find('iframe[src]').each(function() {
    var iframe = $(this).get(0);
    iframe.contentWindow.location.href = iframe.src;
  });

  function objectFitImages(images) {
    var bots = /bot|googlebot|crawler|spider|robot|crawling/i;

    if (bots.test(navigator.userAgent) ||
      typeof images === 'undefined') {
      return false;
    }

    if (!(images instanceof jQuery)) {
      images = $(images);
    }

    images.each(function() {
      var image = $(this);

      image
      .wrap('<div class="object-fit-wrapper"></div>')
      .after('<div class="object-fit"></div>');

      // var objectFitWrapper = image.parent();
      var objectFit = image.next();

      objectFit
      .addClass(image.attr('class'))
      .css('background-image', 'url("' + encodeURI(image.attr('src')) + '")')
      .attr('title', image.attr('alt'));

      image.remove();
    });
  }

  objectFitImages(slider.find('img'));
});
