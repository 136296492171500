var options = {
  rotation: 'none',
  duration: 750,
};
var logo = null;
var icons = [
  'zellenkur__frame-2',
  'zellenkur__frame-3',
  'zellenkur__frame-4',
  'zellenkur__frame-5',
  'zellenkur__frame-6',
  'zellenkur__frame-7',
  'zellenkur__frame-8',
  'zellenkur__frame-9',
  'zellenkur__frame-10',
  'zellenkur__frame-11',
  'zellenkur__frame-12',
  'zellenkur__frame-13',
  'zellenkur__frame-14',
  'zellenkur__frame-15',
  'zellenkur__frame-16',
  'zellenkur__frame-17',
  'zellenkur__frame-18',
  'zellenkur__frame-19',
  'zellenkur__frame-20',
  'zellenkur__frame-1',
];
var current = 0;

function initLogo() {
  logo = new SVGMorpheus('#zellenkur__svg', options);
}

function changeLogo() {
  setTimeout(function() {
    if (current + 1 > icons.length)  {
      current -= icons.length;
    }

    logo.to(icons[current]);

    current++;
    changeLogo();
  }, 3000);
}

window.onload = function() {
  initLogo();
  changeLogo();
};
